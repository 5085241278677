import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: -5vh;

    & p {
        font-size: 1.4em;
        padding: 10px 22vw 10px 22vw;
        color: #aaa;
    }

    & a {
        color: #333;
    }

    & a:hover {
        color; #006666;
    }
`

const AboutName = styled.h2`
  font-size: 2.6em;
  color: #006666;
`

const AboutTitle = styled.h3`
  font-size: 1.8em;
  color: #888;
`

export default function AboutPage({ data }) {
  const avatar = data.markdownRemark.frontmatter.avatar
  const name = data.markdownRemark.frontmatter.name
  const title = data.markdownRemark.frontmatter.title
  const body = data.markdownRemark.html
  return (
    <Layout header="About">
      <SEO
        title="Home"
        description="About Bev Hulme, the costume maker behind Detail Designs."
        pathname="/about"
      />
      <AboutContainer>
        <GatsbyImage
          image={getImage(avatar)}
          alt={name}
          style={{ margin: "20px", borderRadius: "50%" }}
        />
        <AboutName>{name}</AboutName>
        <AboutTitle>{title}</AboutTitle>
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </AboutContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { type: { eq: "about-page" } }) {
      html
      frontmatter {
        name
        title
        avatar {
          childImageSharp {
            gatsbyImageData(
              height: 300
              width: 300
              layout: CONSTRAINED
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`
